import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { RecentTrxActivities } from '../lease/recentActivity';
import { TabComponent, TabSection } from './tabs';

export const ActivityCard: React.FC = observer(() => {
    const sections: TabSection[] = [
        {
            key: 'RecentActivity',
            title: 'Recent Activity',
            component: <RecentTrxActivities />,
        },
    ];
    return (
        <Card style={{ paddingTop: '10px', paddingRight: '10px' }}>
            <CardContent>
                <TabComponent
                    sections={sections}
                    onSubmit={() => {}}
                    hideStepperButtons={true}
                    disableSave
                />
            </CardContent>
        </Card>
    );
});
