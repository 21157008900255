import { makeAutoObservable } from 'mobx';
import { getAnalytics, setUserId, logEvent, setUserProperties, settings } from "firebase/analytics";

export class AnalyticsStore {
  fbAnalyticsStore = new FBAnalytics();

  constructor() {
    makeAutoObservable(this);
  }

  getAnalyticsId = (email: string) => {
    return btoa(email);
  }

  updateAnalyticsId = (email: string) => {
    const analyticsId = this.getAnalyticsId(email);
    setUserId(getAnalytics(), analyticsId);

    this.fbAnalyticsStore.updateAnalyticsId(email);
  }

  updateUserProps = (email: string, gender?: string, dob?: number) => {
    setUserId(getAnalytics(), this.getAnalyticsId(email));
    setUserProperties(getAnalytics(), {
      'gender': gender,
      'dob': dob ? (new Date(dob)).toDateString() : null
    });

    this.fbAnalyticsStore.updateUserProps(email, gender, dob);
  }

  trackSignUp = (method?: 'email') => {
    logEvent(getAnalytics(), 'sign_up', { 'method': method });
    this.fbAnalyticsStore.logEvent(true, 'Signup', { method });
  }

  trackLogin = () => {
    logEvent(getAnalytics(), 'login');
    this.fbAnalyticsStore.logEvent(true, 'Login');
  }

  trackSearch = (query: string) => {
    logEvent(getAnalytics(), 'search', {
      'search_term': query
    });
    this.fbAnalyticsStore.logEvent(false, 'Search', { search_string: query, });
  }

  trackPage = (path: string) => {
    logEvent(getAnalytics(), 'page_view', {
      'path': path
    });
    this.fbAnalyticsStore.logEvent(false, 'PageView');
  }

  trackBillPayment = (amount: number, method: string) => {
    logEvent(getAnalytics(), 'bill_payment', {
      'amount': amount,
      'method': method
    });

    this.fbAnalyticsStore.logEvent(true, 'BillPayment', { amount, method });
  }

  trackCreateListing = () => { }
  trackCreateLease = () => { }
}



class FBAnalytics {

  constructor() {
    makeAutoObservable(this);
  }

  updateAnalyticsId = (email: string) => {
    try {
      (window as any).fbq && (window as any).fbq('init', (window as any).fbEventId, { em: email });
    } catch (err) { console.log('Unable to init Meta analytics', err) }
  }

  updateUserProps = (email: string, gender?: string, dob?: number) => {
    try {
      if ((window as any).fbq && (window as any).fbEventId) {
        const fbMeta: any = { em: email };

        if (gender) {
          fbMeta.ge = `${gender?.trim()?.toLowerCase()?.startsWith('m') ? 'm' : 'f'}`
        }

        if (dob) {
          const d = new Date(dob);
          const m = (d.getMonth() + 1).toString().padStart(2, '0')
          const day = (d.getDate()).toString().padStart(2, '0')
          fbMeta.db = `${d.getFullYear()}${m}${day}`;
        }

        (window as any).fbq('init', (window as any).fbEventId, fbMeta);
      }
    } catch (err) { console.log('Unable to init Meta analytics', err) }
  }

  logEvent = (isCustom: boolean, eventName: string, meta: any = {}) => {
    if (!(window as any).fbq) return;
    try {
      const md = { ...meta }

      if (isCustom) {
        (window as any).fbq('trackCustom', eventName, md);
      } else {
        (window as any).fbq('track', eventName, md);
      }
    } catch (err) { console.log('Unable to log Meta analytics', err) }
  }
}