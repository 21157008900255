import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useStyles } from './styles';
import { RootStoreContext } from '../../global/storeContext';
import {
    IEmergencyContact,
    IOccupation,
    IResidenceInfo,
    ITenantProfile,
    IUserBio,
} from 'realhaus-sdk';
import { TenantContact } from './tenantContact';
import { TenantEmployment } from './tenantEmployment';
import {
    AddressesForm,
    AddressFormBeta,
    AddressViewerAddress,
    LandlordInfo,
} from '../address/addressForm';
import { TenantEmergencyContact } from './tenantEmergencyContact';
import { validatePhoneNumber } from '../formComponents/inputfieldPhone';

export const TenantProfileForm: React.FC = observer(() => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState<string | false>('bioPanel');
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { tenantStore, userStore, leaseStore, uiStore } = useContext(RootStoreContext);
    const [userBio, setUserBio] = React.useState<IUserBio>({} as IUserBio);
    const [tenantProfile, setProfile] = React.useState<ITenantProfile>({} as ITenantProfile);
    const [employment, setEmployment] = React.useState<IOccupation>({} as IOccupation);
    const [emergencyContact, setEmergencyContact] = React.useState<IEmergencyContact>(
        {} as IEmergencyContact,
    );
    const [previousAddresses, setPreviousAddresses] = React.useState<AddressViewerAddress[]>([]);

    const [bioChangesMade, setBioChangesMade] = React.useState(false);
    const [contactChangesMade, setContactChangesMade] = React.useState(false);
    const [emergencyContactChangesMade, setEmergencyContactChangesMade] = React.useState(false);
    const [occupationChangesMade, setOccupationChangesMade] = React.useState(false);

    const fetchPreviousAddresses = async (nonRentals: IResidenceInfo[]) => {
        var previousAddresses = [...(nonRentals ?? [])];
        const leases = await leaseStore.getLeaseAgreementsForTenant();
        previousAddresses = [
            ...previousAddresses,
            ...(leases?.map(
                (lease) =>
                    ({
                        address: lease.listingInfo.address,
                        moveinDate: lease.moveinDate,
                        moveoutDate: lease.moveoutDate,
                        leaseId: lease.id,
                    } as AddressViewerAddress),
            ) ?? []),
        ];
        previousAddresses = previousAddresses.sort(
            (a, b) => (b?.moveoutDate ?? 0) - (a?.moveoutDate ?? 0),
        );
        setPreviousAddresses(previousAddresses);
    };

    useEffect(() => {
        console.log('get profile');

        tenantStore.getTenantProfile().then(async (p) => {
            const updateProfile = async (p: ITenantProfile | undefined) => {
                if (!p) {
                    p = {
                        occupation: {
                            incomeType: 'monthly',
                        },
                    } as ITenantProfile;
                    p.previousNonRentalAddresses = [];

                    await tenantStore.updateProfile(p);
                }

                setProfile(p);
                setEmergencyContact(p.emergencyContact ?? ({} as IEmergencyContact));
                setEmployment(p.occupation ?? ({} as IOccupation));
                await fetchPreviousAddresses(p.previousNonRentalAddresses ?? []);
            };
            await updateProfile(p);
        });

        userStore.getProfile().then(async (bio) => {
            const updateBio = async (b: IUserBio | undefined) => {
                if (!b) {
                    const acctProfile = userStore.userProfile;
                    b = {
                        firstname: acctProfile?.firstname,
                        lastname: acctProfile?.lastname,
                        gender: acctProfile?.gender,
                        dateOfBirth: acctProfile?.dateOfBirth,
                    } as IUserBio;
                }
                setUserBio(b);
            };
            await updateBio(bio);
        });
    }, [leaseStore, tenantStore, userStore.userProfile]);

    const handleUserBioChange =
        (prop: keyof IUserBio) => (event: React.ChangeEvent<{ value: unknown }>) => {
            setUserBio({ ...userBio, [prop]: event.target.value as string });
            setBioChangesMade(true);
        };

    const handleEmploymentChange =
        (prop: keyof IOccupation) => (event: React.ChangeEvent<{ value: unknown }>) => {
            var tmpEmployment = { ...employment, [prop]: event.target.value as string };
            setEmployment({ ...tmpEmployment });
            setProfile({ ...tenantProfile, ['occupation' as keyof ITenantProfile]: tmpEmployment });
            setOccupationChangesMade(true);
        };

    const handleAddressUpdate = (addrProp: keyof ITenantProfile) => (address: IResidenceInfo) => {
        handleSaveProfile({ ...tenantProfile, [addrProp]: address });
    };

    const handleEmergencyContactChange = (prop: keyof IEmergencyContact) => (value: any) => {
        var tEmergencyContact = { ...emergencyContact, [prop]: value?.toString() };
        setEmergencyContact({ ...tEmergencyContact });
        setProfile({
            ...tenantProfile,
            ['emergencyContact' as keyof ITenantProfile]: tEmergencyContact,
        });

        if (prop === 'phone' && validatePhoneNumber(value) === true) {
            setEmergencyContactChangesMade(false);
            return;
        }

        setEmergencyContactChangesMade(true);
    };

    const handleSave = async () => handleSaveProfile();
    const handleSaveProfile = async (profile?: ITenantProfile) => {
        uiStore.showLoading();
        try {
            let profileToUpdate = tenantProfile;
            if (!!profile) {
                profileToUpdate = profile;
                setProfile(profile);
            }

            await tenantStore.updateProfile(profileToUpdate);
            setBioChangesMade(false);
            setContactChangesMade(false);
            setOccupationChangesMade(false);
        } catch (err) {
            console.log(err);
        } finally {
            uiStore.hideLoading();
        }
    };

    const handleAddressCreate = (
        address: IResidenceInfo & {
            landlordInfo?: LandlordInfo | undefined;
        },
    ) => {
        if (address.landlordInfo) {
            leaseStore
                .addNewLeaseAgreementByTenant(address.landlordInfo, {
                    address: address.address,
                    leaseStartDate: address.moveinDate,
                    leaseEndDate: address.moveoutDate ?? 0,
                })
                .then(() => {
                    fetchPreviousAddresses(tenantProfile.previousNonRentalAddresses);
                });
        } else {
            const p = {
                ...tenantProfile,
                previousNonRentalAddresses: [
                    ...(tenantProfile.previousNonRentalAddresses ?? []),
                    {
                        address: address.address,
                        moveinDate: address.moveinDate,
                        moveoutDate: address.moveoutDate,
                    },
                ],
            };

            tenantStore.updateProfile(p).then(() => {
                setProfile(p);
                fetchPreviousAddresses(p.previousNonRentalAddresses);
            });
        }
    };

    const handleAddressDelete = (address: IResidenceInfo) => {
        const p = {
            ...tenantProfile,
            previousNonRentalAddresses: [
                ...(tenantProfile.previousNonRentalAddresses ?? []).filter(
                    (x) =>
                        x.moveinDate !== address.moveinDate &&
                        x.moveoutDate !== address.moveoutDate,
                ),
            ],
        };
        tenantStore.updateProfile(p).then(() => {
            setProfile(p);
            fetchPreviousAddresses(p.previousNonRentalAddresses);
        });
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Accordion
                        expanded={expanded === 'contactPanel'}
                        onChange={handleChange('contactPanel')}
                        style={{ width: '100%' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='contactPanel-content'
                            id='contactPanel-header'
                        >
                            <Typography className={classes.heading}>Contact</Typography>
                            <Typography className={classes.secondaryHeading}>
                                Your Contact Information
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} alignItems='center' justifyContent='center'>
                                <Grid item xs={12}>
                                    <AddressFormBeta
                                        title='Current Address'
                                        address={tenantProfile?.address}
                                        setAddress={handleAddressUpdate('address')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AddressesForm
                                        title='Previous Addresses'
                                        addresses={previousAddresses}
                                        onAddressCreated={handleAddressCreate}
                                        onAddressDeleted={handleAddressDelete}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button size='small'>Cancel</Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                                disabled={!contactChangesMade || uiStore.loading}
                            >
                                Save
                            </Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'occupationPanel'}
                        onChange={handleChange('occupationPanel')}
                        style={{ width: '100%' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='occupationPanel-content'
                            id='occupationPanel-header'
                        >
                            <Typography className={classes.heading} variant='h5'>
                                Occupation
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                Your Employment Information
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TenantEmployment
                                employment={employment}
                                updateEmployment={handleEmploymentChange}
                            />
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button size='small'>Cancel</Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                                disabled={!occupationChangesMade || uiStore.loading}
                            >
                                Save
                            </Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'emergencyPanel'}
                        onChange={handleChange('emergencyPanel')}
                        style={{ width: '100%' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='emergencyPanel-content'
                            id='emrgencyPanel-header'
                        >
                            <Typography className={classes.heading}>Emergency Contact</Typography>
                            <Typography className={classes.secondaryHeading}>
                                Emergency Contact Information
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TenantEmergencyContact
                                profile={emergencyContact}
                                updateProfile={handleEmergencyContactChange}
                            />
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button size='small'>Cancel</Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                                disabled={!emergencyContactChangesMade || uiStore.loading}
                            >
                                Save
                            </Button>
                        </AccordionActions>
                    </Accordion>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.mobileView}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography className={classes.heading}>Bio</Typography>
                                    <Typography className={classes.secondaryHeading} gutterBottom>
                                        Your Bio Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TenantContact profile={userBio} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* Do not allow tenant to edit their user profile here */}
                    {/* <AccordionActions>
                    <Button size='small'>Cancel</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSave}
                        disabled={!bioChangesMade || uiStore.loading}
                    >
                        Save
                    </Button>
                </AccordionActions> */}
                </Grid>
            </Grid>
        </>
    );
});
