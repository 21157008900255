import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid } from '@material-ui/core';
import { RootStoreContext } from '../../global/storeContext';
import { fetchPreviousAddresses } from '../../utils/address';
import { ITenantProfile, IdLeaseAgreement } from 'realhaus-sdk';
import { AttributeRating } from './summary';
import { useStyles } from './styles';
import { format } from 'date-fns';
import { AddressViewerAddress } from '../address/addressCard';
import { Grades, GradeType } from 'realhaus-sdk';

interface RentHistoryComponentProps {
    tenant: ITenantProfile | undefined;
    tenantId: string | undefined;
    grades?: Grades;
}

interface LeaseAgreementInfo {
    landlordInfo?: { firstname: string; lastname: string };
    leaseAgreement?: IdLeaseAgreement;
}

export const RentHistoryComponent: React.FC<RentHistoryComponentProps> = observer(
    ({ tenant, tenantId, grades }) => {
        const { leaseStore, userStore } = useContext(RootStoreContext);
        const classes = useStyles();
        const [rentHistory, setRentHistory] = useState<AddressViewerAddress[]>();
        const [leaseInfoMap, setLeaseInfoMap] = useState<Map<string, LeaseAgreementInfo>>(
            new Map<string, LeaseAgreementInfo>(),
        );

        useEffect(() => {
            const getpreviousAddresses = async () => {
                if (tenant?.previousNonRentalAddresses) {
                    if (!tenantId) return;

                    const previousAddresses = await fetchPreviousAddresses(
                        tenantId,
                        leaseStore,
                        tenant?.previousNonRentalAddresses,
                    );
                    setRentHistory(previousAddresses);

                    const leaseMap = new Map<string, LeaseAgreementInfo>();

                    const leaseAgreementForTenants = await leaseStore.getLeaseAgreementByTenantId(
                        tenantId,
                    );
                    if (!!leaseAgreementForTenants) {
                        const tasks = leaseAgreementForTenants.map(async (lease) => {
                            const li = {
                                leaseAgreement: leaseAgreementForTenants?.find(
                                    (x) => lease.id === x.id,
                                ),
                                landlordInfo: !!lease.ownerId
                                    ? await userStore.getUserProfile(lease.ownerId)
                                    : undefined,
                            } as LeaseAgreementInfo;

                            leaseMap.set(lease.id, li);
                            return li;
                        });

                        await Promise.all(tasks);
                        setLeaseInfoMap(leaseMap);
                    }
                }
            };
            getpreviousAddresses();
        }, [tenantId]);

        const getLandlordName = (leaseId?: string) => {
            if (!leaseId || !leaseInfoMap.get(leaseId)?.leaseAgreement?.ownerId)
                return 'Unavailable';

            const landlord = leaseInfoMap.get(leaseId)?.landlordInfo;

            return `${landlord?.firstname} ${landlord?.lastname}`;
        };

        const getRentAmount = (leaseId?: string) => {
            if (!leaseId || !leaseInfoMap.get(leaseId)?.leaseAgreement?.rentAmount) return 'N/A';

            const amount = leaseInfoMap.get(leaseId)?.leaseAgreement?.rentAmount;
            return !!amount ? `$${amount} / mo` : 'Unavailable';
        };
        return (
            <Box>
                <Typography variant='h6' gutterBottom={true}>
                    RENT HISTORY
                </Typography>

                {rentHistory?.map((history, i) => (
                    <Box sx={{ border: '1px solid #e8e8e8', p: 3, mb: 4 }} key={i}>
                        <Grid container spacing={2} className={classes.space}>
                            <Grid item md={4}>
                                <Typography variant='body1'>
                                    <b>
                                        {`${format(history.moveinDate, 'PP')} -
                                        ${
                                            history.moveoutDate
                                                ? format(history.moveoutDate, 'PP')
                                                : null
                                        }`}
                                    </b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Term
                                </Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant='body1'>
                                    <b>{`${history.address.streetAddress}`}</b>
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    color='textSecondary'
                                >{`${history.address.city} ${history.address.province}, ${history.address.country}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.space}>
                            <Grid item md={4}>
                                <Typography variant='body1'>
                                    <b>{!!history.leaseId ? 'Rented' : 'Not-Rented'}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'>
                                    <b>{getRentAmount(history.leaseId)}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Rent
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'>
                                    <b>{getLandlordName(history.leaseId)}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Landlord
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            {Object.keys(grades || {}).map((gradeType, i) => (
                                <Grid item md={3} key={`rating-grade-${i}`}>
                                    <AttributeRating
                                        totalPossibleRating={5}
                                        rating={!!grades ? grades[gradeType as GradeType] : 0}
                                        title={gradeType}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Box>
        );
    },
);
