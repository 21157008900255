import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Link } from '@material-ui/core';
import { ImgContainer, useStyles } from './styles';
import { RealhausLogo } from '../main/logo';

export const AuthLayout: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const classes = useStyles();
    const bkgImg = `/img/auth/background_${Math.floor(Math.random() * 5) + 1}.jpg`;

    return (
        <Grid container>
            <Grid item sm={12} md={5} className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Link href='/'>
                            <RealhausLogo />
                        </Link>
                    </Grid>
                    <Grid item xs={12} alignContent='center' alignItems='center'>
                        <Grid
                            container
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            style={{ marginTop: '100px' }}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={7} className={classes.fullHeight}>
                <Box display={{ sm: 'none', xs: 'none', md: 'block' }}>
                    <ImgContainer backgroundUrl={bkgImg} />
                </Box>
            </Grid>
        </Grid>
    );
});
