import React, { useEffect } from 'react';
import { PaymentMethod } from './billPaymentCheckout';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';
import { FinixApplicationId, FinixEnvironment, FinixMerchantId } from '../../appConfig';
import { Dialog, DialogContent, Divider, Grid, Typography } from '@material-ui/core';

export const FinixPaymentForm: React.FC<{
    openFinixTokenModal: boolean;
    onClose: (paymentSuccessful: boolean) => void;
    leaseId: string;
    billId: string;
    feeBreakdown?: { [key in PaymentMethod]: { fee: number; netAmount: number } };
    selectedPaymentMethod: PaymentMethod | undefined;
}> = observer(
    ({ openFinixTokenModal, onClose, feeBreakdown, leaseId, billId, selectedPaymentMethod }) => {
        const formRef = React.useRef<HTMLDivElement>(null);
        const { uiStore, walletStore, analyticsStore } = React.useContext(RootStoreContext);

        const closeDialog = () => {
            onClose(false);
        };

        useEffect(() => {
            if (!selectedPaymentMethod || !feeBreakdown) {
                return;
            }
            uiStore.showLoading();
            const { fee, netAmount } = feeBreakdown[selectedPaymentMethod];

            // create transactionLog for card payment
            const initiatePayment = (tokenId: string, sessionId: string) =>
                walletStore.initiateBillPayment(
                    netAmount,
                    fee,
                    leaseId,
                    billId,
                    `${selectedPaymentMethod}`,
                    { finix: { token: tokenId, sessionId: sessionId } },
                );

            const interval = setInterval(() => {
                if ((window as any).Finix && formRef.current) {
                    clearInterval(interval); //stop interval once finix is loaded
                    uiStore.hideLoading();
                    const onSubmit = () => {
                        // call the form submit function and supply the environment and application ID to submit to
                        form.submit(
                            FinixEnvironment,
                            FinixApplicationId,
                            function (err: any, res: any) {
                                if (!!err) {
                                    console.error(err);
                                    uiStore.error('Card payment failed');
                                    return;
                                }
                                // get token ID from response
                                const tokenData = res.data || {};
                                const token = tokenData.id;

                                //   get fraud sessionId
                                (window as any).Finix.Auth(
                                    FinixEnvironment,
                                    FinixMerchantId,
                                    async (sessionKey: any) => {
                                        uiStore.showLoading();
                                        try {
                                            // pass tokenId and fraud sessionId to initiate payment
                                            const res = await initiatePayment(token, sessionKey);
                                            if (!!res?.trxRefId) {
                                                analyticsStore.trackBillPayment(
                                                    netAmount,
                                                    `${PaymentMethod.CREDITCARD}`,
                                                );
                                                uiStore.success('Payment successful');
                                                onClose(true);
                                            } else {
                                                const msg =
                                                    'We were unable to pay your bill through credit card at this time. Please try again later';
                                                const err = res?.errorMsg
                                                    ? `${res.errorMsg}\n${msg}`
                                                    : msg;
                                                uiStore.error(err);
                                                console.error(err);
                                                onClose(false);
                                            }
                                        } catch (err) {
                                            uiStore.error('Payment request failed');
                                            console.error(err);
                                        } finally {
                                            uiStore.hideLoading();
                                        }
                                    },
                                );
                            },
                        );
                    };
                    const options = {
                        // show address fields in the form (default is false)
                        showAddress: true,
                        //show labels in the form (default is true)
                        showLabels: true,
                        // set custom labels for each field
                        labels: {
                            name: 'Name on card *',
                            number: 'Card number *',
                            expiration_date: 'Expiration *',
                            security_code: 'CVV *',
                            address_postal_code: 'Postal code *',
                            address_country: 'Country/ Region *',
                        },
                        // turn on or off placeholder text in the fields (default is true)
                        showPlaceholders: true,
                        // set custom placeholders for each field, you can specify them here
                        placeholders: {
                            name: 'John Doe',
                            number: '0000 0000 0000 0000',
                            security_code: '123',
                            address_postal_code: 'A1A 1A1',
                            address_country: 'Canada',
                        },
                        // require any specific fields that are not required by default, you can specify them here
                        requiredFields: [
                            'name',
                            'number',
                            'expiration_date',
                            'security_code',
                            'address_postal_code',
                            'address_country',
                        ],
                        // hide specific fields that you do not need
                        hideFields: [
                            'address_line1',
                            'address_line2',
                            'address_city',
                            'address_state',
                            'address_region',
                        ],
                        defaultValues: {
                            address_country: 'CAN',
                            // Supported Fields:  "name", "security_code", "bank_code", "account_type", "address_line1", "address_line2", "address_city", "address_state","address_region", "address_country", "address_postal_code"
                            // name: "John Doe",
                        },
                        // if you want to require a field, but not hide input error messages (default is false)
                        hideErrorMessages: false,
                        // set custom error messages for each field if you are showing error messages
                        errorMessages: {
                            name: 'Check your cardholder name.',
                            number: 'Check your card number.',
                            expiration_date: 'Check your expiration date.',
                            security_code: 'Check your CVV code.',
                            address_postal_code: 'Check your postal code. This field is required.',
                            address_country: 'Select a country',
                        },
                        // custom styles for the form inputs (optional but recommended)
                        styles: {
                            input: {},
                            // default styling for all fields
                            default: {
                                border: '1px solid #CCCDCF',
                                borderRadius: '8px',
                                padding: '10px 16px',
                                fontFamily: 'Helvetica',
                                fontSize: '16px',
                                color: 'rgb(100, 100, 100)',
                                boxShadow:
                                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)',
                            },
                            // specific styling if the field is valid
                            success: {},
                            // specific styling if the field has errors
                            error: {
                                border: '1px solid rgba(255,0,0, 0.3)',
                            },
                        },
                        onLoad: function () {},
                        onSubmit,
                        submitLabel: 'Confirm and Pay',
                    };
                    const form = (window as any).Finix.CardTokenForm('form-element', options);
                }
            }, 100);
            return () => clearInterval(interval);
        }, [openFinixTokenModal]);

        return (
            <>
                <Dialog open={openFinixTokenModal} onClose={closeDialog} maxWidth='sm' fullWidth>
                    <DialogContent>
                        <Grid
                            container
                            style={{
                                marginTop: '15px',
                                marginBottom: '15px',
                                padding: '15px',
                            }}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Typography variant='h5'>Provide Card Details</Typography>
                                <Divider
                                    variant='fullWidth'
                                    style={{ marginTop: '8px', width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div id='form-element' ref={formRef}></div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    },
);
