import { Button, Grid, Theme, Typography, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ILeaseRatingReviewDetails, LeaseTerm } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import StringUtils from '../../utils/string';
import { RatingReviewDialog } from './ratingReviewDialog';
import { Card, CardContent, Tooltip } from '@mui/material';
import { useIsMobile } from '../uiComponents/UIComponents';

const LightTooltip = withStyles((theme: Theme) => ({
    popper: {
        '& .MuiTooltip-tooltip': {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.8)',
            boxShadow: theme.shadows[1],
            fontSize: '12px',
            border: '1px solid rgba(0, 0, 0, 0.8)',
        },
    },
}))(Tooltip);

export const PendingReviews: React.FC<{}> = observer(() => {
    const { ratingStore, uiStore } = React.useContext(RootStoreContext);

    const fetchPendingReviews = () => {
        ratingStore.getPendingReviews(uiStore.isLandlordMode());
    };

    useEffect(() => {
        fetchPendingReviews();
    }, []);

    const handleRatingReviewCompleted = () => {
        fetchPendingReviews();
    };
    const isMobile = useIsMobile();
    return (
        <>
            {ratingStore.PendingReviews && ratingStore.PendingReviews.length > 0 && (
                <>
                    <Card style={{ padding: isMobile ? '10px' : '50px' }}>
                        <Typography align='left' variant='h6'>
                            <small>Pending Reviews ({ratingStore.PendingReviews.length})</small>
                        </Typography>

                        <CardContent
                            style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}
                        >
                            {ratingStore.PendingReviews.map((l: ILeaseRatingReviewDetails) => {
                                return (
                                    <Grid container key={l.leaseAgreementId} spacing={4}>
                                        <PendingReviewCard
                                            review={l}
                                            handleReviewCompleted={handleRatingReviewCompleted}
                                        />
                                    </Grid>
                                );
                            })}
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    );
});

export const PendingReviewCard: React.FC<{
    review: ILeaseRatingReviewDetails;
    handleReviewCompleted: () => void;
}> = observer(({ review, handleReviewCompleted }) => {
    const [openRatingDailog, setOpenRatingDailog] = React.useState(false);
    const [revieweeFirstName, setRevieweeFirstname] = React.useState<string>('');
    const [address, setAddress] = React.useState<string>('');
    const { userStore, uiStore, leaseStore } = React.useContext(RootStoreContext);

    const isLandlord = uiStore.isLandlordMode();

    const handleOpen = () => {
        setOpenRatingDailog(true);
    };
    const handleClose = () => {
        setOpenRatingDailog(false);
    };

    const termToReview = (
        isLandlord ? !review.midterm.ownerCompletedOn : !review.midterm.tenantCompletedOn
    )
        ? LeaseTerm.midterm
        : LeaseTerm.endterm;

    useEffect(() => {
        if (!!review) {
            // get leaseAgreement
            leaseStore.getLeaseAgreementById(review.leaseAgreementId).then((lease) => {
                if (!!lease) {
                    // set listing address
                    setAddress(lease.listingInfo.address.streetAddress);

                    // get reviewee info
                    userStore
                        .getUserProfile(isLandlord ? lease.tenantIds[0] : lease.ownerId)
                        .then((user) => {
                            if (!!user) {
                                setRevieweeFirstname(user.firstname);
                            }
                        });
                }
            });
        }
    }, []);

    const userReviewTitle = `${StringUtils.toTitleCase(
        termToReview,
    )} review for ${revieweeFirstName} at ${address}`;
    const isMobile = useIsMobile();

    return (
        <>
            <Grid container item spacing={2} justifyContent='space-between'>
                <Grid item xs={9}>
                    <LightTooltip title={userReviewTitle} placement='bottom'>
                        <Typography
                            variant='subtitle1'
                            style={{ cursor: 'pointer' }}
                        >{` ${StringUtils.truncateString(
                            userReviewTitle,
                            isMobile ? 20 : 50,
                        )}`}</Typography>
                    </LightTooltip>
                </Grid>
                <Grid item xs={3} justifyContent='center' style={{ display: 'flex' }}>
                    <Button
                        onClick={() => {
                            handleOpen();
                        }}
                        color='primary'
                        variant='outlined'
                    >
                        Review
                    </Button>
                </Grid>
            </Grid>
            <RatingReviewDialog
                open={openRatingDailog}
                handleCloseModal={handleClose}
                revieweeFirstName={revieweeFirstName}
                review={review}
                term={termToReview}
                handleReviewCompleted={handleReviewCompleted}
            />
        </>
    );
});
