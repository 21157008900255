import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Grid, Link } from '@material-ui/core';
import { ImgContainer, useStyles } from './styles';
import { RealhausLogo } from '../main/logo';

export const BlankLayout: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const classes = useStyles();
    const bkgImg = `/img/auth/background_${Math.floor(Math.random() * 5) + 1}.jpg`;
    return (
        <Container
            maxWidth={'lg'}
            style={{
                marginTop: '20px',
                marginBottom: '20px',
                backgroundColor: 'transparent',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ minHeight: '60px' }}>
                    <Link href='/'>
                        <RealhausLogo />
                    </Link>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item sm={12} md={6} className={classes.content}>
                        <Grid container>{children}</Grid>
                    </Grid>
                    <Grid item md={6} className={classes.fullHeight}>
                        <Box display={{ sm: 'none', xs: 'none', md: 'block' }}>
                            <ImgContainer backgroundUrl={bkgImg} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
});
