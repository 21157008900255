import { BankAccountPurpose, IDTransactionLog, IExchangePlaidLinkTokenRequest } from 'realhaus-sdk';
import { firebaseConfig } from '../firebase.config';

interface IApiRequestParameters<T> {
  token: string;
  body: T;
};

interface IAddTenantLeasePayload {
  leaseId: string;
}

interface IBankAccountPurposeRequest {
  bankAccountPurpose: BankAccountPurpose;
}

interface IGetBankAccountsByIdRequest {
  id: string;
}

export const addTenantFromInviteToLease = async (request: IApiRequestParameters<IAddTenantLeasePayload>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/addTenantToLease`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    return response.status === 200;
  } catch (ex) {
    console.error('An error occurred while adding tenant to lease agreement!');
  }
  return false;
};

interface IBillPayRequest {
  amount: number;
  trxFees: number;
  leaseId: string;
  billId: string;
  method: string;
  vendorPayload?: IVendorPayload
}

export interface IVendorPayload {
  finix: { token?: string, sessionId?: string }
}

interface IBillPayResponse {
  errorMsg?: string;
  trxRefId?: string;
  interacUrl?: string;
  trxLogId?: string;
  paramountInteracPayload?: {
    details: string;
    merchantId: string;
    iv: string
    paymentUrl: string;
  }
}

export const initiateBillPayment = async (req: IApiRequestParameters<IBillPayRequest>): Promise<IBillPayResponse | undefined> => {
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/wallet/paybill`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...req.body })
    });

    if (response.ok) {
      return (await response.json()) as IBillPayResponse;
    } else {
      console.error(`Paybill Response ${response.status}`, { responseStatus: response.status, responseText: response.statusText });
      console.error(response.json());
      console.error(response.statusText);
      if (response.status === 409) {
        return { errorMsg: response.statusText };
      }
    }
  } catch (err) {
    console.error(err);
    throw new Error("Failed to initiate payment ");
  }
  finally { }
}


export const resendWithdrawalInstruction = async (req: IApiRequestParameters<{ trxLogId: string }>): Promise<boolean | undefined> => {
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/wallet/sendWithdrawInstructions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...req.body })
    });
    return response.status === 200
  } catch (err) {
    console.log(err);
    console.error('An error occured while trying to resend withdrawal instructions');
    throw new Error('Failed to send withdraw instructions');
  }
  finally { }
}

export const getPlaidLinkToken = async (token: string) => {
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/create-token`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const exchangePlaidPublicToken = async (request: IApiRequestParameters<IExchangePlaidLinkTokenRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/exchange-token`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const removeBankAccountConnection = async (request: IApiRequestParameters<IBankAccountPurposeRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/remove-bank-connection`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const getBankAccounts = async (request: IApiRequestParameters<IBankAccountPurposeRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/get-bank-account-connection`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const getBankAccountsByIdHandler = async (request: IApiRequestParameters<IGetBankAccountsByIdRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/get-bank-account-by-id`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};


interface ISendMoney {
  amount: number;
  receiverId: string;
  notes: string;
}

export const sendMoney = async (request: IApiRequestParameters<ISendMoney>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/sendMoney`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...body }),
    })
    return response.status === 200;
  }
  catch (err) {
    console.log(err);
    console.error('An error occured while trying to send money to tenent');
  }
}

interface IRequestPayment {
  amount: number;
  leaseId: string;
  debtorId: string;
  dueDate: number;
  reason: string;
  attachments?: string[]
}

export const requestPayment = async (req: IApiRequestParameters<IRequestPayment>) => {
  const { token, body } = req;

  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/requestPayment`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...body })
    })

    return response.status === 200
  }
  catch (err) {
    console.error(err);
    console.error('An error occured while trying to request for payment')
  }

}

export const declineIDVerification = async (req: IApiRequestParameters<{ inquiryId: string }>) => {
  const { token, body } = req;

  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/persona/cancel-verification`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...body })
    })

    return response.status === 200
  }
  catch (err) {
    console.error(err);
    console.error('An error occured while trying to decline ID Verification');
    return;
  }
}