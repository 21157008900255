import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import {
    IBankAccount,
    IBill,
    IdLeaseAgreement,
    IOccupant,
    IRequestDocumentType,
    ITenantInfo,
    LeaseAgreementStatus,
    LeaseEndAction,
    LeaseRequestDocStatus,
    OccupantRelationship,
    RentDueDay,
} from 'realhaus-sdk';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RootStoreContext } from '../../../global/storeContext';
import { InputField } from '../../formComponents/inputfield';
import { SelectField } from '../../formComponents/selectfield';
import { LeaseAgreementView } from './documents';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { ListItem, useMediaQuery } from '@mui/material';
import { FileCopy, Delete } from '@material-ui/icons';
import StringUtils from '../../../utils/string';
import { ConfirmDialog } from '../../uiComponents/confirmationDialog';

export const LeaseOverviewComponent: React.FC<{
    leaseAgreement: IdLeaseAgreement;
    pendingRequests: ({ id: string } & IRequestDocumentType)[];
    reFetchPendingRequests: () => Promise<void>;
    setPendingRequests: React.Dispatch<
        React.SetStateAction<
            ({
                id: string;
            } & IRequestDocumentType)[]
        >
    >;
}> = observer(({ leaseAgreement, pendingRequests, reFetchPendingRequests, setPendingRequests }) => {
    const { leaseStore, uiStore, tenantStore, bankAccountStore } = useContext(RootStoreContext);
    const [tenants, setTenants] = useState<ITenantInfo[]>([]);
    const [occupants, setOccupants] = useState<IOccupant[]>(leaseAgreement.occupants || []);
    const [showAddOccupant, setShowAddOccupant] = useState(false);
    const [leaseViewOpen, setLeaseViewOpen] = useState(false);
    const [securityDepositBill, setSecurityDepositBill] = useState<IBill[]>([]);
    const [tenantBankAccount, setTenantBankAccounts] = useState<IBankAccount>();
    const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);
    const [openRequestDialog, setRequestDialog] = React.useState<boolean>(false);

    const [openPendingRequestDialog, setOpenPendingRequestDialog] = React.useState<boolean>(false);

    const statusLabel = `${leaseAgreement.status}`.replaceAll('-', ' ');

    const isLandlord = uiStore.isLandlordMode();

    const leaseEndActionValues = [
        {
            label: 'renew on a month-to-month basis automatically',
            value: LeaseEndAction.RENEW_MONTHLY,
        },
        {
            label: 'terminate automatically on the end date below',
            value: LeaseEndAction.TERMINATE,
        },
    ];

    const getLeaseEndActionLabel = (action: LeaseEndAction): string => {
        return (
            leaseEndActionValues.find(({ value }) => action === value)?.label ||
            'reach-out to landlord for renewal details'
        );
    };

    const updateLeaseOccupants = (occupants: IOccupant[]) => {
        setOccupants([...occupants]);
        if (!occupants.length) return;
        leaseStore
            .updateOccupants(leaseAgreement, occupants)
            .then((a) => {
                uiStore.success('List of occupants updated');
            })
            .catch((e) => {
                uiStore.error('Unable to update list of occupants');
            });
    };

    const handleShowAddOccupant = () => {
        setShowAddOccupant(true);
    };

    const handleCoseAddOccupant = () => {
        setShowAddOccupant(false);
    };

    const handleLeaseOpen = () => {
        setLeaseViewOpen(true);
    };

    const handleLeaseClose = () => {
        setLeaseViewOpen(false);
    };

    const handleRequestDialogOpen = () => {
        setRequestDialog(true);
    };

    const handleRequestDialogClose = () => {
        setRequestDialog(false);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const handlePendingRequestDialogOpen = () => {
        setOpenPendingRequestDialog(true);
    };

    const handlePendingRequestDialogClose = () => {
        setOpenPendingRequestDialog(false);
    };

    const handleAddOccupant = (newOccupant: IOccupant) => {
        console.log({ occupants, newOccupant });
        if (!newOccupant) return;
        const filteredOccupants = occupants.filter((oldOccupant) => {
            return !(
                newOccupant.firstName === oldOccupant.firstName &&
                newOccupant.lastName === oldOccupant.lastName
            );
        });
        const updatedOccupants = [...filteredOccupants, newOccupant];
        updateLeaseOccupants(updatedOccupants);
    };

    const handleRemoveOccupants = (index: number) => () => {
        if (!occupants.length) return;
        occupants.splice(index, 1);
        updateLeaseOccupants(occupants);
    };

    const RentDueText = (value: RentDueDay | number) => {
        const prefix = 'Rent due on';
        if (!value) return `${prefix} First of month`;
        switch (value) {
            case RentDueDay.FIRST_OF_MONTH:
                return `${prefix} First of month`;
            case RentDueDay.END_OF_MONTH:
                return `${prefix} End of month`;
            default:
                return `${prefix} ${value} of month`;
        }
    };

    useEffect(() => {
        const init = async () => {
            if (!leaseAgreement) {
                return;
            }

            const tenants = await Promise.all(
                leaseAgreement.tenantIds.map(async (id) => {
                    const profile = await tenantStore.getProfile(id);
                    const userBio = await tenantStore.getUserBio(id);
                    return { ...profile, ...userBio } as ITenantInfo;
                }),
            );

            if (tenants.length) {
                setTenants(tenants);
            }

            if (occupants.length == 0) {
                const lease = await leaseStore.getLeaseAgreementById(leaseAgreement.id);
                if (lease) {
                    setOccupants([...lease.occupants]);
                }
            }
            await leaseStore.getLeaseBills(leaseAgreement.id).then((bills) => {
                const securityDeposit = bills.filter((bill) => bill.type === 'SECURITY_DEPOSIT');
                setSecurityDepositBill(securityDeposit);
            });

            if (leaseAgreement.tenantIds.length > 0) {
                await bankAccountStore
                    .getBankAccountsById(leaseAgreement.tenantIds[0])
                    .then((bank) => {
                        setTenantBankAccounts(bank?.data);
                    });
            }
        };
        init();
    }, [leaseAgreement]);

    return (
        <>
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={6}>
                    <Typography variant='h6'>Action Items</Typography>
                </Grid>
                <Grid item xs={6} alignItems='center'>
                    <Box display='flex' justifyContent='flex-end'>
                        <IconButton
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            onClick={handleOpenMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id='simple-menu'
                        anchorEl={openMenu}
                        keepMounted
                        open={Boolean(openMenu)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >
                        {isLandlord && (
                            <MenuItem onClick={handleRequestDialogOpen}>Request Document</MenuItem>
                        )}
                    </Menu>
                </Grid>
            </Grid>
            <Box mt={2}></Box>
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Grid item xs={4}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>ITEM</b>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>STATUS</b>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>ACTION</b>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='body1'>Lease Agreement</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1' style={{ textTransform: 'capitalize' }}>
                            {statusLabel}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={handleLeaseOpen} variant='outlined'>
                            View
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='body1'>Security Deposit</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1'>
                            {securityDepositBill.length !== 0
                                ? securityDepositBill[0].status.charAt(0).toUpperCase() +
                                  securityDepositBill[0].status
                                      .replaceAll('_', ' ')
                                      .slice(1)
                                      .toLowerCase()
                                : 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <Typography variant='body1'>Request Deposit</Typography> */}
                    </Grid>
                </Grid>
                {/* Hide this for now until we have a vendor for schedule direct payments */}
                {/* <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='body1'>Auto-Pay setup</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1'>
                            {!!tenantBankAccount ? 'Complete' : 'Incomplete'}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid> */}
                <Grid container item></Grid>
                {/* request documents */}
                {pendingRequests.length > 0 && (
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={4}>
                            <Typography variant='body1'>
                                {pendingRequests.length > 1
                                    ? `${pendingRequests.length} documents request`
                                    : `${pendingRequests.length} document request`}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1'>{LeaseRequestDocStatus.PENDING}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='outlined' onClick={handlePendingRequestDialogOpen}>
                                View
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Box mb={2}></Box>
            <Divider />
            <Box mt={2}></Box>
            <Typography variant='h6'>Lease Terms</Typography>
            <Box mt={2}></Box>
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>DATES</b>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>RENT</b>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={14}>
                            <b>SECURITY DEPOSIT</b>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Typography variant='body1'>
                            {format(leaseAgreement.moveinDate, 'PP')} to{' '}
                            {format(leaseAgreement.moveoutDate, 'PP')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='body1'>$CAD {leaseAgreement.rentAmount}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1'>
                            $CAD {leaseAgreement.securityDepositAmount}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={6}></Box>
                <Grid container item xs={12}>
                    {!!leaseAgreement.fees.lateRentFee && (
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Late rent fee of <b>{leaseAgreement.fees.lateRentFee}</b>
                            </Typography>
                        </Grid>
                    )}
                    {!!leaseAgreement.leaseEndAction && (
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                After lease end,{' '}
                                <b>{getLeaseEndActionLabel(leaseAgreement.leaseEndAction)}</b>
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>
                            <b>{RentDueText(leaseAgreement.dueRentDay)}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Box mb={2}></Box>
                <Divider />
                <Box mt={2}></Box>
                <Grid container>
                    <Grid container item spacing={2} direction='row' justifyContent='flex-start'>
                        <Grid item>
                            <Typography variant='h6'>Occupants</Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                flexGrow: '2',
                                flexDirection: 'row-reverse',
                            }}
                        >
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={handleShowAddOccupant}
                                disabled={leaseAgreement.status === LeaseAgreementStatus.TERMINATED}
                            >
                                <AddIcon /> Add Occupants
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}></Box>
            <Grid container>
                <Grid container item xs={12} sm={10}>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1'>Relationship</Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    <Divider style={{ width: '100%', marginBottom: '1rem' }} />
                    {tenants.map((tenant, i) => (
                        <Grid container item spacing={2} key={`key-main-tenant-${i}`}>
                            <Grid item xs={6}>
                                <Typography variant='body1'>{`${tenant.firstname} ${
                                    tenant.lastname
                                } (${i === 0 ? 'Main' : 'Secondary'})`}</Typography>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={2}>
                                <Box pb={4}></Box>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={2}>
                        {occupants.map((occupant, i) => (
                            <Grid container item spacing={2} key={`key-occupant-${i}`}>
                                <Grid item xs={6}>
                                    <Typography variant='body1'>{`${occupant.firstName} ${occupant.lastName}`}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='body1'>{occupant.relationship}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Link
                                        href='#'
                                        variant='body1'
                                        color='error'
                                        onClick={handleRemoveOccupants(i)}
                                    >
                                        REMOVE
                                    </Link>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <AddOccupantDialog
                dialogOpen={showAddOccupant}
                onAccept={handleAddOccupant}
                closeDialog={handleCoseAddOccupant}
            />
            <LeaseAgreementView
                leaseAgreement={leaseAgreement}
                viewOpen={leaseViewOpen}
                closeView={handleLeaseClose}
            />
            <RequestDocumentDialog
                dialogOpen={openRequestDialog}
                closeDialog={handleRequestDialogClose}
                tenantName={tenants[0]?.firstname}
                leaseAgreementId={leaseAgreement.id}
                reFetchPendingRequest={reFetchPendingRequests}
            />

            <PendingRequestDocumentDialog
                dialogOpen={openPendingRequestDialog}
                closeDialog={handlePendingRequestDialogClose}
                tenantName={tenants[0]?.firstname}
                leaseAgreementId={leaseAgreement.id}
                pendingRequests={pendingRequests}
                setPendingRequests={setPendingRequests}
                reFetchPendingRequests={reFetchPendingRequests}
            />
        </>
    );
});

export interface IRequestDoc {
    id: number;
    title: string;
    description: string;
}

const RequestDocumentDialog: React.FC<{
    dialogOpen: boolean;
    closeDialog: () => void;
    tenantName: string;
    leaseAgreementId: string;
    reFetchPendingRequest: () => Promise<void>;
}> = observer(
    ({ dialogOpen, closeDialog, tenantName, leaseAgreementId, reFetchPendingRequest }) => {
        const [counter, setCounter] = React.useState<number>(0);
        const [docs, setDocs] = React.useState<IRequestDoc[]>([
            { id: 0, title: '', description: '' },
        ]);
        const { leaseStore, uiStore } = React.useContext(RootStoreContext);

        const handleAdd = () => {
            setCounter(counter + 1);
            setDocs((prevDoc) => [...prevDoc, { id: counter + 1, title: '', description: '' }]);
        };

        const handleTextChange =
            (id: number, text: keyof IRequestDoc) =>
            (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setDocs((prevDoc) =>
                    prevDoc.map((doc) =>
                        doc.id === id ? { ...doc, [text]: e.target.value } : doc,
                    ),
                );
            };

        const handleDelete = (id: number) => {
            setDocs((prevDoc) => prevDoc.filter((doc) => doc.id !== id));
        };

        const handleRequest = () => {
            uiStore.showLoading();
            leaseStore
                .addRequestDocuments(leaseAgreementId, docs)
                .then((requestIds) => {
                    if (!!requestIds && requestIds.length > 0) {
                        uiStore.success('Requests successfully sent');
                        reFetchPendingRequest();
                        closeDialog();
                    } else {
                        uiStore.error('Requests failed');
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        };

        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <DialogTitle>
                        <Typography variant='h6' align='center'>
                            Request Document from {tenantName ?? ''}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent='center'>
                            {docs.map((doc) => (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={2}
                                    key={doc.id}
                                >
                                    {/* title */}
                                    <Grid item xs={5}>
                                        <TextField
                                            id='docTitle'
                                            value={doc.title}
                                            onChange={handleTextChange(doc.id, 'title')}
                                            label='Title'
                                            margin='normal'
                                            variant='outlined'
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    {/* description */}
                                    <Grid item xs={5}>
                                        <TextField
                                            id='docDescription'
                                            value={doc.description}
                                            onChange={handleTextChange(doc.id, 'description')}
                                            label='description'
                                            margin='normal'
                                            variant='outlined'
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid container item xs={2} alignItems='center'>
                                        <Grid item>
                                            <IconButton
                                                style={
                                                    doc.id === 0
                                                        ? { display: 'none' }
                                                        : { display: 'block' }
                                                }
                                                onClick={() => handleDelete(doc.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleAdd}
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                            Add
                        </Button>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Box m={'10px'}>
                            <Button onClick={closeDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button color='primary' onClick={handleRequest} variant='contained'>
                                Request
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);

const PendingRequestDocumentDialog: React.FC<{
    dialogOpen: boolean;
    closeDialog: () => void;
    tenantName: string;
    leaseAgreementId: string;
    pendingRequests: ({ id: string } & IRequestDocumentType)[];
    setPendingRequests: React.Dispatch<
        React.SetStateAction<
            ({
                id: string;
            } & IRequestDocumentType)[]
        >
    >;
    reFetchPendingRequests: () => Promise<void>;
}> = observer(
    ({
        dialogOpen,
        closeDialog,
        tenantName,
        leaseAgreementId,
        pendingRequests,
        setPendingRequests,
        reFetchPendingRequests,
    }) => {
        const [docToDelete, setDocToDelete] = React.useState<
            {
                id: string;
            } & IRequestDocumentType
        >();
        const { leaseStore, uiStore } = React.useContext(RootStoreContext);
        const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
        const [requestsToUpload, setRequestsToUpload] = React.useState<
            ({ id: string; file: File } & IRequestDocumentType)[]
        >([]);
        const fileInputRefs = React.useRef<Array<HTMLInputElement | null>>([]);
        const isLandlordMode = uiStore.isLandlordMode();
        const isMobile = useMediaQuery('(max-width:441px)');

        const hanldeConfirmDelete = () => {
            setConfirmDelete(true);
        };

        const handleDeletePendingRequest = async (
            doc: {
                id: string;
            } & IRequestDocumentType,
        ) => {
            uiStore.showLoading();
            await leaseStore
                .deleteRequestDocument(leaseAgreementId, doc)
                .then(() => {
                    uiStore.success('document request deleted');
                    setPendingRequests((prevs) => prevs.filter((prev) => prev.id !== doc.id));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        };

        const handleFileSelected = (
            e: React.ChangeEvent<HTMLInputElement>,
            doc: { id: string } & IRequestDocumentType,
            index: number,
        ) => {
            if (!e.currentTarget.files || !doc) return;

            const file = e.currentTarget.files[0];
            const fileSize = Math.round(file.size / 1024);
            const maxFileSize = 3072;
            if (fileSize > maxFileSize) {
                // file too large
                const errorMsg = 'Invalid file. The file size must not be more than 3 mb';
                console.error(errorMsg);
                uiStore.error(errorMsg);
                if (fileInputRefs.current[index]) {
                    fileInputRefs.current[index]!.value = ''; // Clear the file input
                }
                return;
            }
            setRequestsToUpload((prevs) => {
                const index = prevs.findIndex((prev) => prev.id === doc.id);

                if (index !== -1) {
                    return prevs.map((prev) => (prev.id === doc.id ? { ...prev, file } : prev));
                } else {
                    return [...prevs, { file, ...doc }];
                }
            });
        };

        const handleSubmitRequestDocs = async () => {
            console.log(requestsToUpload);
            uiStore.showLoading();
            if (requestsToUpload.length > 0) {
                try {
                    const ids = await leaseStore.uploadRequestDocs(
                        requestsToUpload,
                        leaseAgreementId,
                    );
                    if (!!ids && ids.length > 0) {
                        ids.length > 1
                            ? uiStore.success('Requested documents uploaded successfully')
                            : uiStore.success('Requested document uploaded successfully');
                        reFetchPendingRequests();
                        closeDialog();
                    } else {
                        uiStore.error('upload failed');
                    }
                } catch (err) {
                    uiStore.error('An invalid file. A file size must not be more than 3 mb');
                } finally {
                    uiStore.hideLoading();
                }
            } else {
                uiStore.error('Please select a file');
                uiStore.hideLoading();
            }
        };

        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <DialogTitle>
                        <Typography variant='h6' align='center'>
                            Documents requested from {tenantName ?? ''}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}>
                                {pendingRequests.map((doc, index) => (
                                    <>
                                        <List key={index}>
                                            <ListItem>
                                                {!isMobile && (
                                                    <ListItemAvatar>
                                                        <Avatar sizes='small'>
                                                            <FileCopy />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                )}

                                                <ListItemText
                                                    primary={doc.title}
                                                    secondary={StringUtils.truncateString(
                                                        doc.description,
                                                    )}
                                                />

                                                {isLandlordMode ? (
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            edge='end'
                                                            aria-label='delete'
                                                            onClick={() => {
                                                                setDocToDelete(doc);
                                                                hanldeConfirmDelete();
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                ) : (
                                                    <ListItemText>
                                                        <input
                                                            accept='*'
                                                            id={doc.id}
                                                            type='file'
                                                            ref={(inputRef) =>
                                                                (fileInputRefs.current[index] =
                                                                    inputRef)
                                                            }
                                                            value={
                                                                fileInputRefs.current[index]?.value
                                                            }
                                                            onChange={(e) =>
                                                                handleFileSelected(e, doc, index)
                                                            }
                                                        />
                                                    </ListItemText>
                                                )}
                                            </ListItem>
                                            {/* <Divider component='li' /> */}
                                        </List>
                                    </>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Box m={'10px'}>
                            <Button onClick={closeDialog} color='primary'>
                                Cancel
                            </Button>
                            {!isLandlordMode && (
                                <Button
                                    color='primary'
                                    onClick={handleSubmitRequestDocs}
                                    variant='contained'
                                >
                                    submit
                                </Button>
                            )}
                        </Box>
                    </DialogActions>
                </Dialog>

                <ConfirmDialog
                    title='Confirm Delete Requests'
                    content={
                        <Typography>
                            Are you sure you want to delete "{docToDelete?.title}" request? This
                            action cannot be undone.
                        </Typography>
                    }
                    openDialog={confirmDelete}
                    closeDialog={(confirm: boolean) => () => {
                        if (confirm && docToDelete) {
                            handleDeletePendingRequest(docToDelete);
                        }

                        setConfirmDelete(false);
                    }}
                />
            </>
        );
    },
);

type OccupantDialogProps = {
    dialogOpen: boolean;
    onAccept: (occupant: IOccupant) => void;
    onReject?: () => void;
    closeDialog: () => void;
};

const AddOccupantDialog: React.FC<OccupantDialogProps> = observer(
    ({ dialogOpen, onAccept, closeDialog }) => {
        const [occupant, setOccupant] = useState<IOccupant>();
        const handleOccupantInputChange = (prop: keyof IOccupant) => (value: string | null) => {
            setOccupant({ ...occupant, [prop]: value } as IOccupant);
        };
        const handleOccupantUpdate = () => {
            if (occupant && occupant.firstName && occupant.lastName && occupant.relationship) {
                onAccept(occupant);
                handleClose();
            }
        };
        const handleOccupantRelationship = (value: string | number | null) => {
            setOccupant({ ...occupant, relationship: value as OccupantRelationship } as IOccupant);
        };
        const handleClose = () => {
            setOccupant({} as IOccupant);
            closeDialog();
        };

        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <>
                        <DialogTitle id='alert-dialog-title'>Add Occupant</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <InputField
                                        id='firstName'
                                        name='firstName'
                                        label='First Name'
                                        fullWidth
                                        required
                                        placeholder='First name'
                                        value={occupant?.firstName ?? ''}
                                        onChange={handleOccupantInputChange('firstName')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        fullWidth
                                        required
                                        placeholder='Last name'
                                        value={occupant?.lastName ?? ''}
                                        onChange={handleOccupantInputChange('lastName')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectField
                                        id='relationship'
                                        label='Relationship'
                                        fullWidth={true}
                                        variant='outlined'
                                        onChange={handleOccupantRelationship}
                                        value={occupant?.relationship}
                                        values={Object.entries(OccupantRelationship).map(
                                            (entries) => {
                                                return { label: entries[1], val: entries[1] };
                                            },
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleClose} color='primary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleOccupantUpdate}
                                color='primary'
                                variant='contained'
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            </>
        );
    },
);
