import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Popover, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useStyles } from './leaseStyles';
import {
    IdLeaseAgreement,
    LeaseAgreementStatus,
    LeaseAgreementViewer,
    ITenantInfo,
    ITenantLeaseInvite,
    AutoDebitSetupStatus,
    IdBill,
    IRequestDocumentType,
    BillStatus,
} from 'realhaus-sdk';
import { streetAddress } from '../../utils/address';
import StarIcon from '@material-ui/icons/Star';
import { LeaseStore } from '../../stores/leaseStore';

interface LeaseSnapshotProps {
    lease: IdLeaseAgreement;
    viewer: LeaseAgreementViewer;
    tenants: ITenantInfo[];
    tenantInvitationInfo?: ITenantLeaseInvite;
    isActive?: boolean;
    onSelected: () => void;
    tenantInvites?: Map<string, ITenantLeaseInvite>;
    leaseStore?: LeaseStore;
}

export const LeaseSnapshot: React.FC<LeaseSnapshotProps> = observer(
    ({ lease, viewer, tenants, tenantInvitationInfo, isActive, onSelected, leaseStore }) => {
        const [data, setData] = useState<{
            bills: IdBill[];
            requestedDocs: IRequestDocumentType[];
        }>({
            bills: [],
            requestedDocs: [],
        });
        const classes = useStyles();

        const tenantNames = () => {
            let tNames = '';
            if (tenants && tenants.length > 0) {
                tenants.forEach((tenant, index) => {
                    tNames += `${tenant.firstname} ${tenant.lastname}`;
                    if (index < lease.tenantIds.length - 1) tNames += ', ';
                });
            } else if (tenantInvitationInfo) {
                tNames = `${tenantInvitationInfo?.firstname} ${tenantInvitationInfo?.lastname}`;
            } else {
                tNames = '--';
            }

            return tNames;
        };

        const isActiveForTenant = () => {
            return (
                LeaseAgreementViewer.TENANT === viewer &&
                LeaseAgreementStatus.SIGNED === lease.status &&
                lease.moveoutDate > Date.now()
            );
        };

        const getData = async () => {
            if (!leaseStore) return;
            const bills = await leaseStore.getLeaseBills(lease.id);
            const docs = await leaseStore.getPendingRequestDocuments(lease.id);
            setData({ bills, requestedDocs: docs as unknown as IRequestDocumentType[] });
        };

        useEffect(() => {
            getData();
        }, []);

        const leaseStatus = () => {
            switch (lease.status) {
                case LeaseAgreementStatus.DRAFT:
                    return {
                        status: LeaseAgreementStatus.DRAFT,
                        description:
                            'Your lease agreement is being prepared and will be sent to you for review and signature',
                    };
                case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE:
                    return {
                        status: LeaseAgreementStatus.PENDING_TENANT_SIGNATURE,
                        description:
                            'The landlord has created and sent the lease agreement to you. If you are not satisfied with the condition, request modification. Otherwise, Sign the document.',
                    };
                case LeaseAgreementStatus.TERMINATED:
                    return {
                        status: LeaseAgreementStatus.TERMINATED,
                        description: 'This lease has ended.',
                    };

                case LeaseAgreementStatus.CANCELLED:
                    return {
                        status: LeaseAgreementStatus.CANCELLED,
                        description: 'This lease has been cancelled.',
                    };
                case LeaseAgreementStatus.SIGNED:
                    if (
                        LeaseAgreementStatus.SIGNED === lease.status &&
                        (lease.autoDebitSetup?.status !== AutoDebitSetupStatus.COMPLETED ||
                            data.requestedDocs.length > 0 ||
                            data.bills.filter((b) => b.status === BillStatus.PENDING).length > 0)
                    ) {
                        return {
                            status: 'Action Items Pending',
                            description:
                                'You have some outstanding action items to resolve before your lease becomes active. This could include auto-pay setup, required documents, security deposit or fees, etc. Review the action items in the lease agreement.',
                        };
                    }

                    if (
                        LeaseAgreementStatus.SIGNED === lease.status &&
                        lease.moveinDate > Date.now()
                    ) {
                        return {
                            status: 'Pre-Move-In',
                            description:
                                'You have satisfied all action items and your lease will become active on your move in date.',
                        };
                    }
                    if (
                        LeaseAgreementStatus.SIGNED === lease.status &&
                        lease.moveoutDate > Date.now()
                    ) {
                        return {
                            status:
                                LeaseAgreementViewer.TENANT === viewer ? 'Active' : lease.status,
                            description: `Your lease agreement is ${
                                LeaseAgreementViewer.TENANT === viewer ? 'active' : lease.status
                            }.`,
                        };
                    }
                    break;
                default:
                    return {
                        status: '--',
                        description: '',
                    };
            }
        };

        const autoPay = () => {
            if (lease.policies.autoPaymentSetup) {
                return classes.successColor;
            } else return classes.errorColor;
        };

        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);

        return (
            <Box
                className={classes.cardParent}
                onClick={onSelected}
                style={{
                    backgroundColor: `${isActive ? '#f5f5f5' : 'transparent'}`,
                }}
                key={lease.id}
            >
                {isActiveForTenant() && (
                    <Grid
                        container
                        style={{
                            position: 'absolute',
                            width: '22px',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <StarIcon color='primary' />
                    </Grid>
                )}
                <Grid container direction='row'>
                    <Grid item xs={12} md={3} className={classes.cardContent}>
                        <Typography variant='body1'>
                            <b>{tenantNames()}</b>
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {lease.listingInfo.address
                                ? `${streetAddress(lease.listingInfo.address)} ${
                                      lease.listingInfo.address.city
                                  }`
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cardContent}
                        aria-owns={
                            leaseStatus()?.description && open
                                ? `mouse-over-popover-${lease.id}`
                                : undefined
                        }
                        aria-haspopup='true'
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <Typography variant='body1' className={classes.capitalization}>
                            <b>{leaseStatus()?.status}</b>
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            STATUS
                        </Typography>
                        {leaseStatus()?.description && (
                            <Popover
                                id={`mouse-over-popover-${lease.id}`}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Typography style={{ padding: 10, maxWidth: 400 }}>
                                    {leaseStatus()?.description}
                                </Typography>
                            </Popover>
                        )}
                    </Grid>
                    {/* grid of two */}
                    <Grid item xs={12} md={3} className={classes.cardContent}>
                        <Grid container direction='row'>
                            <Grid item xs={6} className={classes.capitalization}>
                                <Typography variant='body1'>
                                    <b>{format(lease.moveinDate, 'PP')}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    START DATE
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.cardContent}>
                                <Typography variant='body1'>
                                    <b>{format(lease.moveoutDate, 'PP')}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    END DATE
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container direction='row'>
                            <Grid item xs={6} className={classes.cardContentAlign}>
                                <Typography variant='body1' className={classes.successColor}>
                                    <b>{lease.rentAmount ?? '--'}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    RENT
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.cardContentAlign}>
                                <Typography variant='body1' className={autoPay()}>
                                    <b>
                                        {lease.policies.autoPaymentSetup ? 'Enabled' : 'Disabled'}
                                    </b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    AUTOPAY
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    },
);
