import { PropertyTypeEnum } from 'realhaus-sdk';
import { IFilterListingParams, ISearchListingQuery } from '../interfaces/search';

/**
 * Initialized the values in the filter search params using the values provided in
 *  the URL if they exist.
 * @param params The URL search params
 * @returns The filter params that were resolved from the URL
 */
export const initializeFilterParams = (params: URLSearchParams): IFilterListingParams => {
  return {
    type: params.has('type') ? params.get('type') as PropertyTypeEnum : undefined,
    moveIn: params.has('moveIn') ? parseInt(params.get('moveIn') as string) : undefined,
    minPrice: params.has('minPrice') ? parseInt(params.get('minPrice') as string) : undefined,
    maxPrice: params.has('maxPrice') ? parseInt(params.get('maxPrice') as string) : undefined,
    bedrooms: params.has('bedrooms') ? params.get('bedrooms')?.split(',') : undefined,
    bathrooms: params.has('bathrooms') ? params.get('bathrooms')?.split(',') : undefined,
  }
}

export const initializeSearchQuery = (params: URLSearchParams): ISearchListingQuery => {
  return {
    query: `${params.get('q')}`.replaceAll('--', ' '),
    distance: params.has('distance') ? parseInt(params.get('distance') as string) : undefined,
  }
}

export const toQueryString = (elements: any[], fieldName: string) => {
  return elements.map((element) => element[fieldName].toLowerCase().replaceAll(' ', '-')).join('--')
}

export const toQueryStringFromArrString = (elements: string[]) => {
  return toQueryString(elements.map((e) => ({ value: e })), 'value');
}