import { observer } from 'mobx-react-lite';
import { BlankLayout } from '../../layouts/misc/blank';
import { Grid, Link, Typography } from '@material-ui/core';

const NotFoundComponent: React.FC = observer(() => {
    return (
        <Grid item spacing={3}>
            <Typography variant='h1' style={{ fontWeight: 'bold', fontSize: '8rem' }}>
                Oops!
            </Typography>
            <Typography variant='h2' style={{ paddingTop: '8px' }}>
                We could not find the page you're looking for.
            </Typography>
            <Typography variant='h5' style={{ paddingTop: '15px' }}>
                Error code: 404
            </Typography>

            <Typography variant='body1' style={{ paddingTop: '15px' }}>
                Try visiting the <Link href='/'>Realhaus's home page instead</Link>
            </Typography>
        </Grid>
    );
});

export const NotFoundPage: React.FC = observer(() => {
    return (
        <>
            <BlankLayout>
                <NotFoundComponent />
            </BlankLayout>
        </>
    );
});
